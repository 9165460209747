<template>
  <section>
    <div class="category-timer-wrapper">
      <Timer v-if="data?.timer?.date" :background="data.timer.pc_background" :text="data.timer.pc_text"
             :color="data.timer.pc_color"
             :timer_color="data.timer.color"
             :date="data.timer.date"/>
    </div>
  </section>
</template>

<style scoped lang="scss">
.category-timer-wrapper {
  @include smd {
    margin: 0 -16px
  }

  .timer {
    padding: 0 10px;
  }
}
</style>
<script>
import Timer from "@/components/molecules/Timer.vue";

export default {
  props: {
    data: Object
  },
  components: {Timer}
}
</script>
